import { createStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  userData: '',
  filter: false,
  data : []
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'setUserData':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = configureStore({
  reducer: changeState,
})
export default store
